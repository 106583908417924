import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  GetAttachments,
  DownloadDocument,
} from "../../services/formAttachment";
import { saveAs } from "file-saver";
import Label from "../atoms/label";
import { Col } from "reactstrap";
import { toast } from "react-toastify";
import Dropzone from "./dropzone";
import Loading from "../atoms/loading";

const FileBox = styled.div`
  border: 1px solid #707070;
  padding: 10px 20px 10px 15px;
  height: 42px;
  font-size: 12px;
  margin-bottom: 5px;
`;
const FileLink = styled.span`
  cursor: pointer;
  :hover {
    color: blue;
  }
`;
export default class UploadFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFiles: [],
      loading: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
  }
  componentDidMount() {
    this.getAttachments();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      if (nextProps.id === "") {
        this.setState({ uploadFiles: [] });
      } else {
        this.getAttachments(nextProps.id);
      }
    }
  }

  getAttachments(id) {
    var newID = id ? id : this.props.id;
    if (newID !== 0 && newID !== null) {
      this.setState({ loading: true });
      GetAttachments({ formID: newID, formType: this.props.formType })
        .then((res) => {
          this.setState({ uploadFiles: res.data, loading: false });
          if (this.props.callback) {
            this.props.callback(this.props.formType, res.data.length > 0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ loading: false });
    }
  }

  downloadFile = (file) => {
    try {
      if (file.type.startsWith("image")) {
        /*GetBase46Stting(file).then((res) => {
          imageString = res.data;
          saveAs(
            "data:application/octet-stream;base64," + imageString,
            file.name
          );
        });*/
      } else {
        let doc = new Blob([file.data], { type: file.type });
        saveAs(doc, file.name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  downloadFilePaired = (file) => {
    try {
      DownloadDocument({ fileId: file.AttachmentID })
        .then((res) => {
          let doc = new Blob([res.data], { type: file.FileType });
          saveAs(doc, file.FileName);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  onFilesAdded = (files) => {
    files.forEach((file) => {
      if (file.size / 1024 / 1024 > (this.props.size || 10)) {
        toast.error(
          "The file is too large. The max file size is " +
            (this.props.size.toString() || "10") +
            "MB."
        );
      }
    });
    this.setState(
      (prevState) => ({
        uploadFiles: prevState.uploadFiles.concat(files),
      }),
      () => {
        this.props.updateUploadFiles(this.state.uploadFiles);
      }
    );
  };

  removeFile = (index) => {
    try {
      let files = this.state.uploadFiles;
      files.splice(index, 1);

      this.setState({ uploadFiles: files }, () => {
        this.props.updateUploadFiles(this.state.uploadFiles);
        toast.success("File removed successfully");
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <>
        <Col xs="12" sm="12" md="2">
          <Label>
            {this.props.title ? this.props.title : "Upload Documents"}{" "}
          </Label>
        </Col>
        <Col xs="12" sm="12" md="12">
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            backColor="#254F9E"
            disabled={this.props.paired}
          ></Dropzone>
        </Col>
        {this.state.loading === true && (
          <Col>
            <Loading></Loading>
          </Col>
        )}
        {!this.props.paired && this.state.loading === false && (
          <Col>
            {this.state.uploadFiles.map((file, index) => {
              return (
                <FileBox key={index}>
                  <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>&nbsp;&nbsp;
                  <FileLink onClick={() => this.downloadFile(file)} id={index}>
                    {file.name}
                  </FileLink>
                  <FileLink
                    style={{ float: "right" }}
                    onClick={() => this.removeFile(index)}
                    id={index}
                  >
                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                  </FileLink>
                </FileBox>
              );
            })}
          </Col>
        )}
        {this.props.paired && this.state.loading === false && (
          <Col>
            {this.state.uploadFiles.map((file, index) => {
              return (
                <FileBox key={index}>
                  <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>&nbsp;&nbsp;
                  <FileLink
                    onClick={() => this.downloadFilePaired(file)}
                    id={index}
                  >
                    {file.FileName}
                  </FileLink>
                </FileBox>
              );
            })}
          </Col>
        )}
      </>
    );
  }
}
