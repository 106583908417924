import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { ChangeTypes } from "../../../data/enums";
import { getUserInfo } from "../../../services/auth";
import {
  GetFeedback,
  SubmitFeedback,
  UpdateFeedbackStatus,
} from "../../../services/incidentReport";
import FormContainer from "../../atoms/formContainer";
import FormDropdown from "../../atoms/formDropdown";
import Hr from "../../atoms/hr";
import SubmitButton from "../../atoms/submitButton";

import {
  faBan,
  faHand,
  faHome,
  faImage,
  faKeyboard,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import Center from "../../atoms/center";
import FormFieldTextArea from "../../atoms/formFieldTextArea";
import FormFieldType from "../../atoms/formFieldType";
import FormHeaderInfo from "../../atoms/formHeader";
import FormHeaderTitle from "../../atoms/formHeaderTitle";
import LargeButton from "../../atoms/largeButton";
import UploadFiles from "../../molecules/upload";

/* eslint-disable */
const initialState = {
  ID: null,
  Category: "Bug",
  Status: "Open",
  Notes: "",
  uploadFiles: [],
  searchTerm: "",
  submitting: false,
  paired: false,
  Submitter: "",
  CreatedBy: getUserInfo().Email,
  CreatedOn: "",
  ModifiedBy: null,
  ModifiedOn: null,
};

export default class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
  }

  componentDidMount = () => {
    var query = queryString.parse(location.search);
    if (location.search !== "" && query !== null && query["id"] !== null) {
      this.setState({ searchTerm: query["id"] }, () => {
        if (this.state.searchTerm !== "") {
          this.formIDSearch();
        }
      });
    }
  };

  updateUploadFiles = (files) => {
    this.state.uploadFiles = files;
  };

  // Handle Submit logic
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true }, () => {
      if (this.state.paired) {
        this.setState({ ModifiedBy: getUserInfo().Email }, () => {
          this.updateStatus();
        });
      } else {
        this.submitForm();
      }
    });
  };

  clear = () => {
    this.setState(initialState);
    if (typeof window !== "undefined") {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };

  updateStatus() {
    UpdateFeedbackStatus({ formData: this.state })
      .then((res) => {
        if (res.data.HasErrors) {
          res.data.ErrorList.forEach((item) => {
            toast.error(item);
          });
        } else {
          toast.success(res.data);
          this.child.current.refreshFormParent();
          this.formIDSearch(this.state.ID);
        }
        this.setState({
          submitting: false,
        });
      })
      .catch((error) => {
        toast.error(error);
        this.setState({ submitting: false });
      });
  }

  submitForm() {
    SubmitFeedback({
      formData: this.state,
      userId: getUserInfo().Email,
      formType: 6,
    })
      .then((res) => {
        if (res.data.HasErrors) {
          res.data.ErrorList.forEach((item) => {
            toast.error(item);
          });
        } else {
          this.clear();

          toast.success(res.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  // Handle Search functionality
  formIDSearch = (id = -1) => {
    this.clear();
    if (
      id === -1 &&
      this.state.searchTerm !== "" &&
      this.state.searchTerm.match(/^[0-9]+$/) == null
    ) {
      toast.error("Feedback Form ID must be a positive number");
      return;
    }
    GetFeedback(
      1,
      0,
      this.state.CreatedBy,
      id !== -1 ? id : this.state.searchTerm
    )
      .then((res) => {
        if (typeof res === "string") {
          toast.error(res);
          return;
        }
        if (res.HasErrors) {
          res.ErrorList.forEach((item) => {
            toast.error(item);
          });
          this.setState({
            submitting: false,
          });
        } else {
          this.setState(res, () => {
            toast.success("Feedback request loaded successfully");
            if (typeof window !== "undefined") {
              window.scrollTo(0, 0);
            }
            this.setState({ paired: true });
          });
        }
      })
      .catch((err) => {
        toast.error("An Error has ocurred");
      });
  };

  // Handle default onChange events
  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: event.target.checked });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value });
        break;
    }
  };
  render() {
    return (
      <>
        <Row>
          <Col>
            <FormContainer>
              <Row>
                <Col md="12" lg="12">
                  <FormHeaderTitle>
                    <FontAwesomeIcon icon={faHand}></FontAwesomeIcon>
                    &nbsp;Suggestion/Feedback Form
                    <FormFieldType lg={true}>{this.state.Status}</FormFieldType>
                  </FormHeaderTitle>
                </Col>
              </Row>

              <form id="form" onSubmit={this.handleSubmit}>
                <FormHeaderInfo>
                  <FontAwesomeIcon icon={faKeyboard}></FontAwesomeIcon>
                  &nbsp;Category
                </FormHeaderInfo>

                <FormDropdown
                  title="Category"
                  value={this.state.Category}
                  disabled={this.state.paired}
                  onChange={(event) => this.handleUserInput(event)}
                  name="Category"
                  options={[
                    { key: "1", value: "Bug", label: "Bug Report" },
                    {
                      key: "2",
                      value: "Suggestion",
                      label: "Suggestion for the website",
                    },
                    { key: "3", value: "Other", label: "Other" },
                  ]}
                  dropdownContentType="Category"
                  required={true}
                ></FormDropdown>
                <Hr />

                <FormHeaderInfo>
                  <FontAwesomeIcon icon={faKeyboard}></FontAwesomeIcon>
                  &nbsp;Comments / Questions
                </FormHeaderInfo>

                <FormFieldTextArea
                  disabled={this.state.paired}
                  title="Notes"
                  value={this.state.Notes}
                  type="textarea"
                  onChange={(event) =>
                    this.handleUserInput(event, ChangeTypes.NOTES)
                  }
                  name="Notes"
                  maxLength="1500"
                  minHeight="275px"
                ></FormFieldTextArea>
                <Hr />

                <FormHeaderInfo>
                  <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>
                  &nbsp;Attachments
                </FormHeaderInfo>
                {!this.state.submitting && (
                  <Row>
                    <UploadFiles
                      updateUploadFiles={this.updateUploadFiles}
                      title={"Attachment"}
                      id={this.state.ID}
                      formType={6}
                      paired={this.state.paired}
                    ></UploadFiles>
                  </Row>
                )}
                <Hr />
                {
                  <>
                    <FormHeaderInfo>
                      <FontAwesomeIcon icon={faTag}></FontAwesomeIcon>
                      &nbsp;Status
                    </FormHeaderInfo>

                    <FormDropdown
                      title="Status"
                      value={this.state.Status}
                      readOnly={this.state.ID === null}
                      onChange={(event) => this.handleUserInput(event)}
                      name="Status"
                      options={[
                        { key: "1", value: "Open", label: "Open" },
                        {
                          key: "2",
                          value: "Resolved by Software Fix",
                          label: "Resolved by Software Fix",
                        },
                        {
                          key: "3",
                          value: "Resolved by Training",
                          label: "Resolved by Training",
                        },
                      ]}
                      dropdownContentType="Status"
                    ></FormDropdown>
                    <Hr />
                  </>
                }

                <Row>
                  <Col xs="12" md="4">
                    <Center>
                      <LargeButton
                        large={true}
                        altColor={true}
                        onClick={this.clear}
                      >
                        <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                        &nbsp; Clear Form
                      </LargeButton>
                    </Center>
                  </Col>
                  {this.state.paired ? (
                    <>
                      <Col xs="12" md="4">
                        <Center>
                          <SubmitButton
                            large={true}
                            text={"Update Status"}
                            submitting={this.state.submitting}
                          ></SubmitButton>
                        </Center>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs="12" md="4">
                        <Center>
                          <SubmitButton
                            large={true}
                            disabled={this.state.Notes === ""}
                            submitting={this.state.submitting}
                          ></SubmitButton>
                        </Center>
                      </Col>
                    </>
                  )}
                  <Col xs="12" md="4">
                    <Center>
                      <LargeButton
                        large={true}
                        altColor={true}
                        to="/forms/incident-report"
                      >
                        <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                        &nbsp;Return Home
                      </LargeButton>
                    </Center>
                  </Col>
                </Row>
              </form>
            </FormContainer>
          </Col>
        </Row>
      </>
    );
  }
}
